<template>
  <div class="contents register wedding funding banner">
    <div class="title flexB">
      <h2>배너 관리</h2>
    </div>
    <div class="box one">
      <el-tabs type="card" v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="홈" name="home">
          <h2 class="bold">홈 배너 미리보기</h2>
        </el-tab-pane>
        <el-tab-pane label="웨딩 장소" name="place"
          ><h2 class="bold">웨딩 장소 배너 미리보기</h2>
        </el-tab-pane>
        <el-tab-pane label="컬렉션" name="collection"
          ><h2 class="bold">컬렉션 배너 미리보기</h2>
        </el-tab-pane>
        <el-tab-pane label="서브" name="sub"
          ><h2 class="bold">서브 배너 미리보기</h2>
        </el-tab-pane>
        <!-- <el-tab-pane label="플래너" name="planner"><h2 class="bold">아카이브 - 플래너 배너 미리보기</h2> </el-tab-pane>
        <el-tab-pane label="포트폴리오" name="portfolio"><h2 class="bold">아카이브 - 포트폴리오 배너 미리보기</h2> </el-tab-pane>
        <el-tab-pane label="스토어" name="store"><h2 class="bold">스토어 배너 미리보기</h2> </el-tab-pane>
        <el-tab-pane label="이벤트" name="event"><h2 class="bold">이벤트 배너 미리보기</h2> </el-tab-pane> -->
      </el-tabs>
      <swiper
        class="swiper"
        :options="swiperOption"
        v-if="images.filter((item) => item.type == activeName).length > 0"
      >
        <swiper-slide
          class="bannerWrap"
          v-for="(data, i) in images.filter((item) => item.type == activeName)"
          :key="i"
        >
          <span>{{ data.label }}</span>
          <h2>
            {{ data.title }}
            <p>{{ data.subtitle }}</p>
            <span>{{ moment(data.eventDate).format("YYYY.MM.DD") }}까지</span>
          </h2>
          <a :href="data.url" target="_blank">
            <img :src="data.image" />
          </a>
        </swiper-slide>

        <div class="swiper-pagination" slot="pagination"></div>
        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>

      <div class="nodata banner" v-else>
        <p>배너를 등록해 주세요.</p>
      </div>
    </div>
    <div class="box one">
      <h2 class="bold">배너 업로드</h2>
      <div class="flexL">
        <p class="bold">라벨</p>
        <input type="text" v-model="label" />
      </div>

      <div class="flexL">
        <p class="bold">메인 타이틀</p>
        <input type="text" v-model="title" />
      </div>
      <div class="flexL">
        <p class="bold">서브 타이틀</p>
        <input type="text" v-model="subtitle" />
      </div>
      <div class="flexL">
        <p class="bold">이벤트 날짜</p>
        <el-date-picker
          v-model="eventDate"
          type="datetime"
          placeholder="선택해 주세요."
          format="yyyy-MM-dd"
          class="fullW"
        >
        </el-date-picker>
      </div>
      <div class="flexL">
        <p class="bold">URL</p>
        <input type="text" v-model="url" />
      </div>
      <div class="flexL">
        <p class="bold">폰트 컬러</p>
        <el-color-picker v-model="fontColor"></el-color-picker>
      </div>
      <div class="flexL">
        <p class="bold">이미지</p>
        <div class="flexB">
          <div class="filebox">
            <label for="imgUpload">찾아보기</label>
            <input
              type="file"
              ref="images"
              id="imgUpload"
              @change="submit()"
              accept="image/*"
              multiple
            />
            <span v-if="activeName == 'home'"
              >500*325 사이즈를 권장합니다.</span
            >
            <span v-else>500*175 사이즈를 권장합니다.</span>
          </div>
        </div>
      </div>
    </div>
    <div
      class="box one"
      v-if="images.filter((item) => item.type == activeName).length > 0"
    >
      <h2 class="bold">배너 리스트</h2>
      <div class="flexL">
        <div>
          <div class="flex imgBox" v-if="images.length > 0">
            <div
              v-for="(item, i) in images.filter(
                (item) => item.type == activeName
              )"
              :key="i"
              class="imgWrap"
            >
              <img :src="item.image" alt="이미지" id="img" />
              <a class="cancel" @click="handleDelete(item._id, item.image)">
                <img
                  src="@/assets/images/icon_svg/ic_delete_wh.svg"
                  alt="icon"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { uploadFile, deleteFile } from "@/api/file";
import { createBanner, fetchBanner, deleteBanner } from "@/api/admin";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import moment from "moment";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      moment: moment,
      activeName: "home",
      url: "",
      label: "",
      eventDate: "",
      title: "",
      subtitle: "",
      fontColor: "#FFFFFF",
      images: [],
      banners: [],
      swiperOption: {
        slidesPerView: 1,
        spaceBetween: 30,
        loop: true,
        autoplay: {
          delay: 2000,
        },
        pagination: {
          el: ".swiper-pagination",
          type: "fraction",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    };
  },
  created() {
    this.$store.dispatch("SET_NAVBAR", "8");
  },
  mounted() {
    this.getBanner();
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    submit() {
      if (this.url == "") {
        this.$confirm("URL 없이 등록하시겠습니까?", {
          confirmButtonText: "확인",
          cancelButtonText: "취소",
          center: true,
        })
          .then(() => {
            this.sendFile();
          })
          .catch(() => {
            return;
          });
      } else {
        this.sendFile();
      }
    },
    sendFile() {
      this.file = this.$refs.images.files[0];
      if (this.file == undefined || this.file == "") {
        return;
      }
      let formdata = new FormData();
      formdata.append("file", this.file);
      uploadFile(formdata).then(async (res) => {
        if (res.status == 200) {
          this.file = "";
          let data = {
            type: this.activeName,
            image: res.data.url,
            url: this.url,
            label: this.label,
            eventDate: this.eventDate,
            title: this.title,
            subtitle: this.subtitle,
            fontColor: this.fontColor,
          };
          await createBanner(data).then((res) => {
            console.log(data, res);
          });
          this.getBanner();
          this.url = "";
        } else {
          this.$alert("서버오류 입니다. 다시 파일을 업로드 해주세요.", {
            confirmButtonText: "확인",
            center: true,
          });
        }
      });
    },
    handleDelete(id, url) {
      this.$confirm("이미지를 삭제하시겠습니까?", {
        confirmButtonText: "삭제",
        cancelButtonText: "취소",
        center: true,
      }).then(() => {
        deleteFile(url).then(async (res) => {
          if (res.status == 200) {
            let data = {
              url: url,
              bannerId: id,
            };
            await deleteBanner(data);
            this.getBanner();
          } else {
            this.$alert("서버오류 입니다. 다시 파일을 업로드 해주세요.", {
              confirmButtonText: "확인",
              center: true,
            });
          }
        });
      });
    },
    getBanner() {
      fetchBanner().then((res) => {
        if (res.data.status == 200) {
          this.images = res.data.data;
        } else {
          this.$alert(res.data.message, {
            confirmButtonText: "확인",
            center: true,
          });
        }
      });
    },
  },
};
</script>
