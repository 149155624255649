import axios from "axios";
import { baseUrl, headers } from "../config/env";

//파일 업로드
export function uploadFile(data) {
  return axios.post(baseUrl + "/api/admin/file/upload", data, {
    headers,
  });
}

// 파일만 삭제
export function deleteFile(url) {
  return axios.delete(baseUrl + `/api/admin/file?url=${url}`, {
    headers,
  });
}

// 파일 삭제
export function deletePostFile(id, url) {
  return axios.delete(baseUrl + `/api/admin/file/post/${id}?url=${url}`, {
    headers,
  });
}
